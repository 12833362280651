export const numberWithCommas = (x, isZeroHide) => {
  return x
    ? String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : isZeroHide
    ? ''
    : 0;
};

export const roomTypeFormat = (roomType) => {
  if (roomType === '5000man') {
    roomType = '5천만';
  } else if (roomType === '2eok') {
    roomType = '2억';
  } else if (roomType === '5eok') {
    roomType = '5억';
  }
  return roomType;
};
