import { makeAutoObservable } from 'mobx';
import io from 'socket.io-client';

export const initialCommon = {
  pwd: undefined,
  socket: null,
  isLogin: false,

  siteMeta: {
    name: '',
    description: '',
    calc_per: '',
    videos: [],
    realPerMin: 10,
    realPerMax: 40,
  },

  screen: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  },
};

class CommonStore {
  constructor(initialData = initialCommon) {
    for (const key in initialData) {
      this[key] = initialData[key];
    }

    makeAutoObservable(this);
  }

  connectSocket = () => {
    if (!this.socket) {
      const _self = this;
      const opt = {
        transports: ['websocket'],
        auth: {
          token: 'nxxR3EVKcFRUTDXr7A5Nrgz3NzmVzfKp8ynk2JB9rNfBdUQny76re4chAcBB',
        },
      };
      _self.socket = io(
        /localhost/.test(window.location.hostname)
          ? `${window.location.protocol}//${window.location.hostname}:5555`
          : `${window.location.protocol}//${window.location.hostname}`,
        opt,
      );
      _self.socket.emit('get site meta');
      _self.socket.on('site meta', (data) => {
        _self.siteMeta = data;
      });
    }
  };
}

export default CommonStore;
