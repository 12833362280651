import { createBreakpoint } from 'styled-components-breakpoint';

const theme = {
  primary: '#bf953f',
  secondary: '#926c1e',
  blue: '#1677ff',
  red: '#e00d06',
  yellow: '#ec9f15',
  blurple: '#4834d4',
  green: '#27ae60',
  greyIcon: 'rgba(0,0,0,.25)',
  err: '#e74c3c',
  shadow: `
  -webkit-box-shadow: 0px 4px 3px -1px #C7C7C7; 
  box-shadow: 0px 4px 3px -1px #C7C7C7;
  `,

  tableHeaderBg: '#fafafa',

  breakpoint: createBreakpoint({
    // xs: 480,
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  }),
};

export default theme;
