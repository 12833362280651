import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, EffectCoverflow } from 'swiper/modules';

import 'swiper/css/effect-coverflow';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100%;
  margin-bottom: 40px;

  .swiper {
    width: 100%;
    height: 250px;
  }

  .swiper-slide {
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: auto;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: 33%;
  }

  .kakao-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 230, 39);
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')` 
  .swiper {
    margin-top: 40px;
    height: 200px;
  }
  .swiper-slide {
    height: 200px;
  }
  .kakao-wrap {
    height: 60px;    
  }
  `}
`;

const Section5 = observer(() => {
  const { commonStore } = useStore();

  return (
    <Wrap>
      {/* <a href="https://open.kakao.com/o/gkcx7PGf" target="_blank">
        <div className="kakao-wrap">
          <img src="/assets/cs.png" alt="" />
        </div>
      </a> */}

      <Swiper
        modules={[A11y, Autoplay, EffectCoverflow]}
        effect={'coverflow'}
        spaceBetween={10}
        slidesPerView={commonStore.screen.lg ? 3 : 2}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
      >
        <SwiperSlide>
          <img className="img" src="/assets/client/1.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="img" src="/assets/client/2.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="img" src="/assets/client/3.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="img" src="/assets/client/4.png" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="img" src="/assets/client/5.png" alt="" />
        </SwiperSlide>
      </Swiper>
    </Wrap>
  );
});

export default Section5;
