import { observer } from 'mobx-react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Home from './components/Home';
import Admin from './components/Admin';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';

const App = observer(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  );
});

export default App;
