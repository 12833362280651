import { useEffect, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import useStore from '@stores/useStore';

import Login from './Login';
import ContactList from './ContactList';
import SiteConfig from './SiteConfig';

import { maskOn, maskOff } from '@utils/mask/index';

import '@utils/mask/mask.css';

const Wrap = styled.div`
  width: 100%;

  .content-wrap {
    width: 100%;
    padding: 20px;

    table {
      th,
      td {
        text-align: center;
      }
    }
  }
`;

const Admin = observer(() => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    tab: 'contact',
  }));

  const handleTab = useCallback((tab) => {
    state.tab = tab;
  }, []);

  useEffect(() => {
    document.querySelector('body').className = 'admin';
    commonStore.connectSocket();

    window.maskOn = maskOn;
    window.maskOff = maskOff;

    setTimeout(() => {
      commonStore.socket.emit('get pwd');
      commonStore.socket.on('adm pwd', (data) => {
        commonStore.pwd = data.pwd;
      });

      if (Cookies.get('tuja_auth') === 'true') {
        commonStore.isLogin = true;
      } else {
        commonStore.isLogin = false;
      }
    }, 50);
  }, []);

  return (
    <Wrap>
      {commonStore.isLogin ? (
        <div className="content-wrap">
          <Tabs activeKey={state.tab} onSelect={handleTab} className="mb-3">
            <Tab eventKey="contact" title="문의사항">
              <ContactList tab={state.tab} />
            </Tab>
            <Tab eventKey="site_config" title="사이트설정">
              <SiteConfig tab={state.tab} />
            </Tab>
          </Tabs>
        </div>
      ) : (
        <Login />
      )}
    </Wrap>
  );
});

export default Admin;
