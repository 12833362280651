import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';

const Wrap = styled.div`
  width: 100%;
  background-color: #111;

  .banner-img {
    width: 100%;
    object-fit: cover;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
  }
  .swiper-pagination-bullet {
    background-color: #fff;
    margin-bottom: 20px !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #fff;
  }

  ${(props) => props.theme.breakpoint('xs', 'sm')`
  .swiper-slide {
    height: 230px;
  }
  `}
`;

const Banner = observer(() => {
  return (
    <Wrap>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop={true}
      >
        <SwiperSlide>
          <img className="banner-img" src="/assets/banner/1.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="banner-img" src="/assets/banner/2.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="banner-img" src="/assets/banner/3.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="banner-img" src="/assets/banner/4.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="banner-img" src="/assets/banner/5.jpg" alt="" />
        </SwiperSlide>
      </Swiper>
    </Wrap>
  );
});

export default Banner;
