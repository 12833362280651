import { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';
import namer from 'korean-name-generator';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import dayjs from 'dayjs';

const Wrap = styled.div`
  width: 100%;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .title {
      display: flex;
      align-items: center;
    }
    .title > div,
    .badge {
      margin-right: 10px;
    }
    .date {
      font-size: 12px;
      color: #888;
    }
  }

  .member-col {
    height: 40.5px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
  }

  i {
    font-size: 14px !important;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')`
  .table {
    margin-bottom: 50px;
  }
  `}
`;

const Section4 = observer(() => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    profits: [],
    members: [],
  }));

  useEffect(() => {
    if (commonStore.socket) {
      commonStore.socket.emit('get realtime profit');
      commonStore.socket.on('cla realtime profit', (data) => {
        state.profits = data;
      });
    }
  }, [commonStore.socket]);

  useEffect(() => {
    const members = [];
    for (let i = 0; i < 20; i++) {
      const name = namer.generate(Math.random() < 0.5);
      let hide = '';
      for (let j = 1; j < name.length - 1; j++) {
        hide += '* ';
      }
      members.push(`${name[0]} ${hide}${name[name.length - 1]}`);
    }
    state.members = members;

    setInterval(() => {
      document.querySelector('#member_0').className =
        'animate__animated animate__fadeOut';
      setTimeout(() => {
        const members = toJS(state.members);
        members.splice(0, 1);
        const name = namer.generate(Math.random() < 0.5);
        let hide = '';
        for (let j = 1; j < name.length - 1; j++) {
          hide += '* ';
        }
        members.push(`${name[0]} ${hide}${name[name.length - 1]}`);
        state.members = members;
        document.querySelector('#member_0').className =
          'animate__animated animate__fadeInRight';
      }, 10);
    }, 5000);
  }, []);

  return (
    <Wrap>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <h4>
                <div className="title-bar"></div>실시간 수익률
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-hover">
                  <tbody>
                    {toJS(state.profits).map((item, idx) => (
                      <tr key={`profit_${idx}`}>
                        <td>
                          <div className="item">
                            <div className="title">
                              <div>{item.name}&nbsp;님</div>
                              <Badge bg="danger">
                                <i className="fa fa-arrow-up" />
                                &nbsp;&nbsp;{Number(item.per).toFixed(2)}%
                              </Badge>
                              <div className="desktop">
                                수익을 축하드립니다!
                              </div>
                            </div>
                            <div className="date">
                              {dayjs(item.date).format('YYYY. MM. DD. HH:mm')}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className=" col-md-6">
          <div className="row">
            <div className="col-md-12">
              <h4>
                <div className="title-bar"></div>실시간 문의 현황
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Row>
                {toJS(state.members).map((name, idx) => (
                  <Col className="member-col" key={`member_${idx}`} xs={3}>
                    <div id={`member_${idx}`}>{name} 님</div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Wrap>
  );
});

export default Section4;
