import { useEffect, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { toJS } from 'mobx';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';

import useStore from '@stores/useStore';

import { numberWithCommas } from '@utils/common';

const Wrap = styled.div`
  .memo {
    height: 35px;
  }
  table tr th,
  table tr td {
    vertical-align: middle;
  }

  .total-wrap {
    display: flex;
    align-items: baseline;

    .divider {
      margin: 0 10px;
    }
  }
  .total span {
    font-size: 2rem;
    color: #007aff;

    &.new {
      color: #e00d06;
    }
  }

  .pagination-wrap {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')`
  table {
    table-layout:fixed;
    width:100%;
    text-align:center;
  }
  table th { 
    background:#ccc;
  }
  table td, .table th {
    padding:10px 20px;
    border-top:1px solid #ccc;
    word-break:break-all
  }
  table thead {
    display:none;
  }
  table tr {
    display:block;
    margin-bottom:10px;
    border-top:none;
  }
  table th, table td {
    display:block;
    position:relative;
    padding: 5px 0;
    padding-left: 32%;
    padding-right: 5px;
    border-width:0 0 1px 0;
  }

  table td:before {
    display:block;
    position:absolute;
    left:0;
    top:0;
    width: 30%;
    height: 100%;
    padding:5px 0;
    background:#ccc;
  }
  .list-table td:nth-child(1):before {content:'이름';}
  .list-table td:nth-child(2):before {content:'연락처';}
  .list-table td:nth-child(3):before {content:'손실금액';}
  .list-table td:nth-child(4):before {content:'손실종목';}
  .list-table td:nth-child(5):before {content:'연락시간';}
  .list-table td:nth-child(6):before {content:'등록일';}
  .list-table td:nth-child(7):before {content:'메모';}
  .list-table td:nth-child(8):before {content:'';}
  `}
`;

const ContactList = observer(({ tab }) => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 20,
      page: 1,
      totalPages: 1,
    },
    newCnt: 0,

    showOffCanvas: false,
  }));

  const handleRemove = useCallback(
    (item) => () => {
      if (
        window.confirm(
          `${item.name} / ${item.phone}\n데이터를 삭제 하시겠습니까?`,
        )
      ) {
        commonStore.socket.emit('remove contact', {
          _id: item._id,
          page: state.paginate.page,
          limit: state.paginate.limit,
        });
      }
    },
    [],
  );

  const handleResizeMemo = useCallback((e) => {
    e.currentTarget.style.height = '35px'; //height 초기화
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  }, []);

  const handleSubmitMemo = useCallback(
    (_id) => (e) => {
      commonStore.socket.emit('set contact memo', {
        _id,
        memo: e.target.value,
      });
    },
    [],
  );

  const handlePage = useCallback(
    (page) => (e) => {
      if (page < 1) {
        page = 1;
      }
      if (page > state.paginate.totalPages) {
        page = state.paginate.totalPages;
      }
      state.paginate.page = page;
      commonStore.socket.emit('get contact list', {
        page: state.paginate.page,
        limit: state.paginate.limit,
      });
    },
    [],
  );

  useEffect(() => {
    if (commonStore.socket && tab === 'contact') {
      window.maskOn();
      commonStore.socket.emit('get contact list', {
        page: state.paginate.page,
        limit: state.paginate.limit,
      });
      commonStore.socket.on('adm contact list', (data) => {
        window.maskOn();
        const page = state.paginate.page;
        const limit = state.paginate.limit;
        state.paginate = {
          ...data.paginate,
          page,
          limit,
        };
        state.newCnt = data.newCnt;
        window.maskOff();

        setTimeout(() => {
          const elems = document.querySelectorAll('.memo');
          if (elems) {
            for (const elem of elems) {
              elem.style.height = '35px'; //height 초기화
              elem.style.height = elem.scrollHeight + 'px';
            }
          }
        }, 10);
      });

      commonStore.socket.on('adm updated list', () => {
        commonStore.socket.emit('get contact list', {
          page: state.paginate.page,
          limit: state.paginate.limit,
        });
      });
    }
  }, [commonStore.socket, tab]);

  return (
    <Wrap>
      <div className="total-wrap">
        <h6 className="total">
          총 <span>{numberWithCommas(state.paginate.totalDocs)}</span> 건
        </h6>
        <div className="divider">/</div>
        <h6 className="total">
          신규 <span className="new">{numberWithCommas(state.newCnt)}</span> 건
        </h6>
      </div>

      <Table className="list-table" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>이름</th>
            <th>연락처</th>
            <th>손실금액</th>
            <th>손실종목</th>
            <th>연락시간</th>
            <th>등록일</th>
            <th>메모</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {toJS(state.paginate.docs).map((item, idx) => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td>{item.phone}</td>
              <td>{item.loss}</td>
              <td>{item.lossType}</td>
              <td>{item.contactTime}</td>
              <td>{dayjs(item.createdAt).format('M월 D일 HH:mm')}</td>
              <td>
                <Form.Control
                  id={`memo_${idx}`}
                  as="textarea"
                  className="memo"
                  placeholder="메모"
                  defaultValue={item.memo}
                  onChange={handleResizeMemo}
                  onBlur={handleSubmitMemo(item._id)}
                />
              </td>
              <td>
                <Button variant="danger" onClick={handleRemove(item)}>
                  삭제
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="pagination-wrap">
        <Pagination>
          <Pagination.First onClick={handlePage(1)} />
          <Pagination.Prev onClick={handlePage(state.paginate.page - 1)} />
          {new Array(state.paginate.totalPages).fill().map((_, idx) => (
            <Pagination.Item
              key={`page_${idx + 1}`}
              onClick={handlePage(idx + 1)}
              active={state.paginate.page === idx + 1}
            >
              {idx + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={handlePage(state.paginate.page + 1)} />
          <Pagination.Last onClick={handlePage(state.paginate.totalPages)} />
        </Pagination>
      </div>
    </Wrap>
  );
});

export default ContactList;
