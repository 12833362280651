import { makeAutoObservable, toJS } from 'mobx';
import Cookies from 'js-cookie';

export const initialAuth = {
  loginOpen: false,
  signupOpen: false,
  signupSms: false,
  agreeOpen: false,
  findUserOpen: false,
  findDoneOpen: false,
  findUserTab: '1',

  commonStore: null,
};

class AuthStore {
  constructor(initialData = initialAuth, root) {
    for (const key in initialData) {
      this[key] = initialData[key];
    }

    this.commonStore = root.commonStore;

    makeAutoObservable(this);
  }

  loginCheck = (apolloClient) => {
    const _self = this;
    let user = null;
    return new Promise(async (resolve) => {
      // user = toJS(_self.commonStore.user);
      // if (user) {
      //   if (user.jwtAccessToken) {
      //     Cookies.set('cgz_jwt', user.jwtAccessToken, {
      //       expires: 7,
      //     });
      //   }
      // } else {
      //   const result = await apolloClient.query({
      //     query: gql`
      //       query User {
      //         user
      //       }
      //     `,
      //   });

      //   if (result && result.data && result.data.user) {
      //     user = result.data.user;
      //     if (user.jwtAccessToken) {
      //       Cookies.set('cgz_jwt', user.jwtAccessToken, {
      //         expires: 7,
      //       });
      //     }
      //   }

      //   _self.commonStore.user = user;
      // }

      resolve(user);
    });
  };
  userCheck = () => {
    if (!this.commonStore.user) {
      this.loginOpen = true;
      return false;
    }
    return true;
  };
}
export default AuthStore;
