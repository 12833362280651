import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as MobxProvider } from 'mobx-react';
import { ThemeProvider } from 'styled-components';

import App from './App';
import reportWebVitals from './reportWebVitals';

import initializeStore from '@stores/index';

import styledTheme from '@styles/styledTheme';
import GlobalStyle from '@styles/globals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <MobxProvider {...initializeStore()}>
    <ThemeProvider theme={styledTheme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </MobxProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
