import { observer } from 'mobx-react';
import styled from 'styled-components';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
  color: #ccc;
  padding: 20px;

  ${(props) => props.theme.breakpoint('xs', 'xl')` 
  `};
`;

const Footer = observer(() => {
  const { commonStore } = useStore();

  return <Wrap>© 2024. {commonStore.siteMeta.name} ALL RIGHTS RESERVED.</Wrap>;
});

export default Footer;
