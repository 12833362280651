import { observer } from 'mobx-react';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100%;

  .img-wrap {
    margin: 40px 0;
  }

  img {
    width: auto;
    object-fit: contain;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')` 
  `}
`;

const Section6 = observer(() => {
  const { commonStore } = useStore();

  return (
    <Wrap>
      <div className="row">
        <div className="col-md-12">
          <h4>
            <div className="title-bar"></div>Individual customized Portfolio
            service
          </h4>
          <p>
            {commonStore.siteMeta.name}는 고객 포트폴리오를 철저하게 전략적으로
            맞춤 선택할 수 있게 제공합니다.
          </p>
        </div>
      </div>

      <div className="img-wrap">
        <img src="/assets/portfolio.png" alt="" />
      </div>

      <div className="row">
        <div className="col-md-12 text-center">
          <a href="#contact" className="button lg dark">
            맞춤형 포트폴리오 문의하기 <i className="fa fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </Wrap>
  );
});

export default Section6;
