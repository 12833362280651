import { useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  position: relative;
  padding-right: 120px;

  .save-btn {
    z-index: 9999;
    width: 100px;
    position: fixed;
    top: 90px;
    right: 20px;
  }
  .row > div {
    margin-bottom: 20px;
  }
  .card {
    margin-bottom: 30px;
  }
  ${(props) => props.theme.breakpoint('xs', 'xl')`
  padding-right: 0;
  padding-bottom: 20px;
  .save-btn {
    top: unset;
    bottom: 0;
    right: 10px;
    width: 100%;
  }
  `}
`;

const SiteConfig = observer(({ tab }) => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 20,
      page: 1,
      totalPages: 1,
    },

    showOffCanvas: false,
  }));

  const handleRemove = useCallback(
    (item) => () => {
      if (window.confirm(`${item.appName} 데이터를 삭제 하시겠습니까?`)) {
        commonStore.socket.emit('remove contact', {
          _id: item._id,
          page: state.paginate.page,
          limit: state.paginate.limit,
        });
      }
    },
    [],
  );

  const handleResizeMemo = useCallback((e) => {
    e.currentTarget.style.height = '35px'; //height 초기화
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  }, []);

  const handleSubmitMemo = useCallback(
    (_id) => (e) => {
      commonStore.socket.emit('set contact memo', {
        _id,
        memo: e.target.value,
      });
    },
    [],
  );

  const handleSubmit = useCallback((e) => {
    const name = document.querySelector('#site_name').value;
    const description = document.querySelector('#site_description').value;
    const calc_per = parseInt(document.querySelector('#calc_per').value);
    const realPerMin = parseInt(document.querySelector('#real_per_min').value);
    const realPerMax = parseInt(document.querySelector('#real_per_max').value);
    const videoTitle1 = document.querySelector('#video_title1').value;
    const videoUrl1 = document.querySelector('#video_url1').value;
    const videoTitle2 = document.querySelector('#video_title2').value;
    const videoUrl2 = document.querySelector('#video_url2').value;
    const videos = [
      { title: videoTitle1, url: videoUrl1 },
      { title: videoTitle2, url: videoUrl2 },
    ];
    commonStore.socket.emit('set site meta', {
      name,
      description,
      calc_per,
      videos,
      realPerMin,
      realPerMax,
    });
    Swal.fire({
      html: '저장 완료!',
      icon: 'success',
      confirmButtonText: '확인',
    });
  }, []);

  return (
    <Wrap>
      <Button className="save-btn" variant="primary" onClick={handleSubmit}>
        저장
      </Button>

      <Card>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Form.Label htmlFor="site_name">사이트명</Form.Label>
              <Form.Control
                type="text"
                id="site_name"
                defaultValue={commonStore.siteMeta.name}
              />
            </Col>
            <Col xs={12}>
              <Form.Label htmlFor="site_description">사이트 설명</Form.Label>
              <Form.Control
                type="text"
                id="site_description"
                defaultValue={commonStore.siteMeta.description}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} md={4}>
              <Form.Label htmlFor="calc_per">예상 수익 복리 퍼센트</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  id="calc_per"
                  defaultValue={commonStore.siteMeta.calc_per}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label htmlFor="calc_per">실시간 수익률 최소</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  id="real_per_min"
                  defaultValue={commonStore.siteMeta.realPerMin}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label htmlFor="calc_per">실시간 수익률 최대</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  id="real_per_max"
                  defaultValue={commonStore.siteMeta.realPerMax}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Row>
            {toJS(commonStore.siteMeta.videos).map((item, idx) => (
              <Col xs={12} key={`video_${idx}`}>
                <Form.Label htmlFor={`video_title${idx + 1}`}>
                  유투브{idx + 1}
                </Form.Label>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Control
                      type="text"
                      id={`video_title${idx + 1}`}
                      placeholder="제목"
                      defaultValue={item.title}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Control
                      type="text"
                      id={`video_url${idx + 1}`}
                      placeholder="URL"
                      defaultValue={item.url}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </Wrap>
  );
});

export default SiteConfig;
