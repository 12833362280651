import { useEffect, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { toJS } from 'mobx';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100%;

  .img {
    width: 100%;
  }

  .swiper {
    margin-top: 40px;
    width: 100%;
    height: 250px;
  }

  .swiper-slide {
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide a {
    width: 100%;
    height: 100%;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #d0d0d0;
  }

  .swiper-slide {
    width: 25%;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')` 
  `}
`;

const Section5 = observer(() => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    list: [],

    show: false,
    imgUrl: '',
  }));

  const handleClose = useCallback(
    (show, url) => () => {
      if (show === true) {
        state.imgUrl = url;
      }
      state.show = show;
    },
    [],
  );

  useEffect(() => {
    if (commonStore.socket) {
      commonStore.socket.emit('get profit');
      commonStore.socket.on('cla profit', (data) => {
        state.list = data.profitImgs;
      });
    }
  }, [commonStore.socket]);

  return (
    <Wrap>
      <div className="row">
        <div className="col-md-12">
          <h4>
            <div className="title-bar"></div>
            {commonStore.siteMeta.name} 회원님들의 진실된 수익 후기
          </h4>
          <p>
            {commonStore.siteMeta.name}를 경험한 회원님들의 진실된 수익 후기를
            눈으로 느껴보세요.
          </p>
        </div>
      </div>

      <Swiper
        modules={[Pagination, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={
          commonStore.screen.xl ? 5 : commonStore.screen.lg ? 3 : 1
        }
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop={true}
      >
        {toJS(state.list).map((item, idx) => (
          <SwiperSlide key={`profit_${idx}`}>
            <a
              href={void 0}
              alt=""
              onClick={handleClose(true, `/profit/${item.name}`)}
            >
              <img className="img" src={`/profit/${item.name}`} alt="" />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      <Modal
        show={state.show}
        onHide={handleClose(false)}
        dialogClassName="profit-dialog"
        contentClassName="profit-content"
      >
        <Modal.Body>
          <CloseButton className="close-btn" onClick={handleClose(false)} />
          <img className="img" src={state.imgUrl} alt="" width="auto" />
        </Modal.Body>
      </Modal>
    </Wrap>
  );
});

export default Section5;
