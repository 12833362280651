import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html, body {  
  overscroll-behavior-x: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "Noto Sans Korean", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;

  &.admin {
    font-size: 14px;

    .btn {
      height: 40px;
    }
  }
}

body {
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer !important;
  text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.primary};
    }
}
p, h1, h2, h3, h4, h5, h6 {
  word-break: keep-all;
}

h4 {
  position: relative;
  padding-left: 15px;
  .title-bar {
    position: absolute;
    left: 0;
    top: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profit-dialog {
  background-color: transparent;
  max-width: fit-content;
}
.profit-content {
  position: relative;
  background-color: transparent;
  border: none;

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.25rem;
  }
}

.calc-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  
}
.calc-content {
  line-height: 35px;
  .spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i {
    font-size: 14px !important;
  }
  .result-calc {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;

    .badge {
      margin-left: 5px;
    }
    & > div{
      margin-right: 10px;
    }
  }
  .date span {
    font-size: 20px;
    font-weight: bold;
  }
  .calc-amount span {
    font-size: 20px;
    font-weight: bold;
    color: #0263d4;
  }
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}
${(props) => props.theme.breakpoint('xs', 'xl')`
  .desktop {
    display: none;    
  }
  .mobile {
    display: block;    
  }
  .calc-dialog {
    width: 90%;

    .result-calc {
      justify-content: flex-start;
    }
  }
  `}
`;

export default GlobalStyle;
