import { configure } from 'mobx';

import CommonStore, { initialCommon } from './CommonStore';
import AuthStore, { initialAuth } from './AuthStore';

configure({
  enforceActions: 'never',
});

const initialRoot = {
  commonStore: initialCommon,
  authStore: initialAuth,
};

let store = null;

export class RootStore {
  commonStore;
  authStore;
  constructor(initialData) {
    this.commonStore = new CommonStore(initialData.commonStore);
    this.authStore = new AuthStore(initialData.authStore, this);
  }
}

const initializeStore = (initialData = initialRoot) => {
  if (store === null) {
    store = new RootStore(initialData);
  }
  return store;
};

export default initializeStore;
