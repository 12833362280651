import { observer } from 'mobx-react';
import styled from 'styled-components';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100%;

  .expert {
    margin-top: 40px;
  }
  p {
    word-break: keep-all;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')`
  `}
`;

const Section3 = observer(() => {
  const { commonStore } = useStore();

  return (
    <Wrap>
      <div className="row">
        <div className="col-md-12">
          <h4>
            <div className="title-bar"></div>
            {commonStore.siteMeta.name} 전문가는 다릅니다.
          </h4>
          <p>
            “온고지신” 20년 이상의 노하우를 지닌 오랜 경험과 퀀트 방식의 완벽한
            시스템 인공지능 신기술이 만났습니다.
          </p>
          <p>
            그 무엇도 비교 불가, 빈틈없는 완벽을 추구하는 전문가들이 함께 모여
            고객님을 하나부터 열까지 책임집니다.
          </p>
        </div>
      </div>

      <div className="row expert">
        <div className="col-md-3">
          <article className="hentry">
            <div className="entry-cover">
              <img src="/profile/1.jpg" alt="images" width="100%" />
            </div>
          </article>
        </div>
        <div className="col-md-3">
          <article className="hentry">
            <div className="entry-cover">
              <img src="/profile/2.jpg" alt="images" width="100%" />
            </div>
          </article>
        </div>
        <div className="col-md-3">
          <article className="hentry">
            <div className="entry-cover">
              <img src="/profile/3.jpg" alt="images" width="100%" />
            </div>
          </article>
        </div>
        <div className="col-md-3">
          <article className="hentry">
            <div className="entry-cover">
              <img src="/profile/4.jpg" alt="images" width="100%" />
            </div>
          </article>
        </div>
      </div>
    </Wrap>
  );
});

export default Section3;
