import { observer } from 'mobx-react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100%;
  background-color: #111;
  padding: 50px;

  .player-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    margin: 0 auto;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .video-title {
    margin-bottom: 20px;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')` 
    padding: 50px 20px;

    .video2 {
      margin-top: 60px;
    }
  `}
`;

const Section2 = observer(() => {
  const { commonStore } = useStore();

  return (
    <Wrap>
      <Row>
        {toJS(commonStore.siteMeta.videos).map((item, idx) => (
          <Col
            md={6}
            key={`video_${idx}`}
            className={idx !== 0 ? 'video2' : ''}
          >
            <div className="title-section">
              <h3 className="title color-ffffff video-title">
                <img
                  src="/assets/youtube_icon.png"
                  className="icon"
                  width="50px"
                />{' '}
                {item.title}
              </h3>
            </div>

            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={item.url}
                width="100%"
                height="100%"
                playing={true}
                loop={true}
                playsinline={true}
                muted={true}
              />
            </div>
          </Col>
        ))}
      </Row>

      <div className="row">
        <div className="col-md-12">
          <div className="flat-border-bottom">
            <span className="sep-holder">
              <span className="sep-line"></span>
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center">
          <a href="#contact" className="button lg dark">
            투자 문의하기 <i className="fa fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </Wrap>
  );
});

export default Section2;
